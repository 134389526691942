export default defineAppConfig({
  saasName: 'FSBD SaaS',
  saasLink: 'https://saas.fsbd.ai',
  customSaas: false,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si la visibilidad es oculto o público se asigna internamente a Taohirata.'
    }
  },
  analyticsLooker: '83555062-8449-4713-a3c9-65ec43bf83fa',
  adify: {
    actions: ['Aumentar', 'Reducir', 'Pausar', 'Mantener', 'Revisar'],
    platforms: ['Google Ads', 'Facebook Ads'],
    vertical: false,
    country: false
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      }
    ]
  }
})